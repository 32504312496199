<template>
  <Container>
    <div
      class="grid rounded-xl shadow-lg sm:grid-cols-[1fr,500px] overflow-hidden"
    >
      <div class="p-8 sm:p-12">
        <h4 class="font-normal text-sm mt-0 mb-4">
          <strong class="text-lg">Number of Bottles</strong>
        </h4>
        <MscQuantityInput
          v-model="bottleAmount"
          required
          type="number"
          :max="2000"
        />

        <h4 class="font-normal text-sm mt-0 mb-4">
          <strong class="text-lg">Fill Weight per Bottle</strong> (enter in
          grams or ounces)
        </h4>
        <MscQuantityInput
          v-model="weightPerBottle"
          required
          :allow-decimals="true"
          type="number"
          :max="2000"
        />

        <MscStrengthInput
          v-model="fragranceLoad"
          step="1"
          :recommend="5"
          :max-load="10"
        />

        <p>Results use the same unit as the fill weight above.</p>
      </div>
      <div class="p-8 sm:p-12 bg-gray-tint space-y-6">
        <div>
          <p class="m-0">
            <strong class="text-md">1. Create Solution:</strong> Combine
            fragrance and EcoBase.
          </p>
          <div class="formula mt-2 shadow rounded-lg">
            <dl class="formula-row">
              <dt class="formula-row-label">Fragrance Weight:</dt>
              <dd class="formula-row-value">
                {{ roundDecimal(fragranceAmount) }}
              </dd>
            </dl>
            <dl class="formula-row">
              <dt class="formula-row-label">EcoBase Weight:</dt>
              <dd class="formula-row-value">
                <span class="formula-row-operator">+</span>
                {{ roundDecimal(totalBatchWeight * ECOBASE_NEEDED) }}
              </dd>
            </dl>
            <dl class="formula-row formula-row-total">
              <dt class="formula-row-label">Total Solution Weight:</dt>
              <dd class="formula-row-value">
                <span class="formula-row-operator">=</span>
                {{
                  roundDecimal(
                    fragranceAmount + totalBatchWeight * ECOBASE_NEEDED,
                  )
                }}
              </dd>
            </dl>
          </div>
        </div>

        <div>
          <p class="m-0">
            <strong class="text-md">2. Dilute Solution:</strong> Add water to
            solution from step 1.
          </p>
          <div class="formula mt-2 shadow rounded-lg">
            <dl class="formula-row">
              <dt class="formula-row-label">Solution Amount (25%):</dt>
              <dd class="formula-row-value">
                {{
                  roundDecimal(
                    fragranceAmount + totalBatchWeight * ECOBASE_NEEDED,
                  )
                }}
              </dd>
            </dl>
            <dl class="formula-row">
              <dt class="formula-row-label">Water Amount (75%):</dt>
              <dd class="formula-row-value">
                <span class="formula-row-operator">+</span>
                {{
                  roundDecimal(
                    (fragranceAmount + totalBatchWeight * ECOBASE_NEEDED) * 3,
                  )
                }}
              </dd>
            </dl>
            <dl class="formula-row formula-row-total">
              <dt class="formula-row-label">Total Mixture Weight</dt>
              <dd class="formula-row-value">
                <span class="formula-row-operator">=</span>
                {{
                  roundDecimal(
                    (fragranceAmount + totalBatchWeight * ECOBASE_NEEDED) * 4,
                  )
                }}
              </dd>
            </dl>
          </div>
        </div>

        <div>
          <p class="m-0"><strong class="text-md">3. Result:</strong></p>
          <div class="formula mt-2 shadow rounded-lg">
            <dl class="formula-row">
              <dt class="formula-row-label">Number of Bottles:</dt>
              <dd class="formula-row-value">{{ bottleAmount }}</dd>
            </dl>
            <dl class="formula-row">
              <dt class="formula-row-label">Fill Weight per Bottle:</dt>
              <dd class="formula-row-value">
                <span class="formula-row-operator">&times;</span>
                {{ roundDecimal(weightPerBottle) }}
              </dd>
            </dl>
            <dl class="formula-row formula-row-total">
              <dt class="formula-row-label">Total Batch Weight:</dt>
              <dd class="formula-row-value">
                <span class="formula-row-operator">=</span>
                {{ roundDecimal(totalBatchWeight) }}
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script setup lang="ts">
  import MscStrengthInput from "@/components/MaterialSupplyCalculator/MscStrengthInput.vue"
  import MscQuantityInput from "@/components/MaterialSupplyCalculator/MscQuantityInput/index.vue"

  const bottleAmount: Ref<number> = ref(20)
  const weightPerBottle: Ref<number> = ref(112)
  const fragranceLoad: Ref<string> = ref("5")

  const ECOBASE_PERCENT = 0.25
  const ECOBASE_NEEDED = computed(() => {
    return ECOBASE_PERCENT - parseInt(fragranceLoad.value) / 100
  })

  const totalBatchWeight = computed(() => {
    if (bottleAmount.value && weightPerBottle.value) {
      return Number(bottleAmount.value) * Number(weightPerBottle.value)
    }
    return 0
  })

  const fragranceAmount = computed(() => {
    if (totalBatchWeight.value) {
      return (parseInt(fragranceLoad.value) / 100) * totalBatchWeight.value
    }
    return 0
  })

  function roundDecimal(num: number) {
    return Math.round(num * 100) / 100
  }
</script>

<style lang="scss" scoped>
  .formula {
    /*    font-weight: bold;*/
    background: white;
    padding: $base-spacing * 3 $base-spacing * 4;
    /*    padding-top: $base-spacing * 2;*/
    /*    border-top: 1px solid $gray-200;*/
    &-row {
      margin: 0;
      display: flex;

      &-label {
        flex: 1;
      }
      &-value {
        text-align: right;
        font-weight: bold;
        white-space: nowrap;
      }
      &-operator {
        color: $gray-600;
        font-weight: normal;
      }
      &-total {
        margin-top: $base-spacing;
        padding-top: $base-spacing;
        border-top: 1px solid $gray-200;
      }
    }
  }
</style>
